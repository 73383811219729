import React, { useState } from 'react';
import Button from 'antd/lib/button/button';
import { Modal } from 'antd';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { observer } from 'mobx-react-lite';

import { ingridientStore } from '../../stores';

import { InputField } from '../../../common/components';
import './addIngridients.global.scss';
import { AddIngredientsValue } from '../../types';

const AddIngridients = (props: any) => {
  const { handleSubmit, values, setFieldTouched, touched, errors, setFieldValue } = props;
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const handleOk = () => {
    handleSubmit();
    setVisible(false);
  };
  const handleCancel = () => setVisible(false);

  return (
    <div>
      <Button onMouseDown={showModal}>Додати новий</Button>
      <Modal
        className="modalWrap"
        title="Додати новий інгредієнт"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <form>
          <InputField
            title={'Назва інгредієнту'}
            placeholder={'Мінімум 2, максимум 60 символів'}
            name="name"
            value={values.name}
            onBlur={() => setFieldTouched('name')}
            errorMessage={touched.name && errors.name}
            onChange={event => setFieldValue('name', event.target.value)}
          />

          <InputField
            title={'Вартість інгредієнту'}
            placeholder={'Вартість'}
            name="price"
            defaultValue={values.price}
            onBlur={() => setFieldTouched('price')}
            errorMessage={touched.price && errors.price}
            onChange={event => setFieldValue('price', event.target.value)}
          />
        </form>
      </Modal>
    </div>
  );
};

const AddIngridientsWithFormik = withFormik<any, AddIngredientsValue>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    name: '',
    price: 0,
    type: 'ADDITIONAL'
  }),
  handleSubmit: (values, { resetForm }) => {
    ingridientStore.create(values);
    resetForm();
  },
  validationSchema: yup.object().shape<AddIngredientsValue>({
    name: yup
      .string()
      .min(2, 'Назва повинна мати не меньше 2 символів')
      .max(60, 'Назва повинна мати не більше 60 символів')
      .required('Це поле не може бути порожнім!')
  }),
  displayName: 'Add ingridients'
})(observer(AddIngridients));

export default AddIngridientsWithFormik;
