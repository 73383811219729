import React, { useState } from 'react';
import Button from 'antd/lib/button/button';
import { Modal, Icon } from 'antd';
import { withFormik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';

import Storage from '../../../common/utils/Storage';

import { InputField } from '../../../common/components';
import './editAdditionIngridient.global.scss';
import { UpdateAdditionIngredientValues, Ingridient } from '../../types';
import { ingridientStore } from '../../stores';

interface EditIngridientsOwnProps {
  ingridient: Ingridient;
}

const EditAdditionIngridients = (props: EditIngridientsOwnProps & FormikProps<UpdateAdditionIngredientValues>) => {
  const { handleSubmit, values, setFieldTouched, touched, errors, setFieldValue, ingridient } = props;
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const handleOk = () => {
    handleSubmit();
    setVisible(false);
  };
  const handleCancel = () => setVisible(false);

  return (
    <div>
      <Button size="small" onMouseDown={showModal}>
        <Icon type="edit" />
      </Button>

      <Modal
        className="modalWrap"
        title="Додати переклад до інгредієнту"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <form>
          <InputField
            title={`Переклад інгредієнту на ${Storage('sessionStorage').get('language')}`}
            placeholder={'Мінімум 2, максимум 60 символів'}
            name="name"
            defaultValue={ingridient.name}
            value={values.name}
            onBlur={() => setFieldTouched('name')}
            errorMessage={touched.name && errors.name}
            onChange={event => setFieldValue('name', event.target.value)}
          />

          <InputField
            title={'Вартість інгредієнту'}
            placeholder={'Вартість'}
            name="price"
            defaultValue={`${values.price}`}
            onBlur={() => setFieldTouched('price')}
            errorMessage={touched.price && errors.price}
            onChange={event => setFieldValue('price', event.target.value)}
          />
        </form>
      </Modal>
    </div>
  );
};

const EditAdditionIngridientsWithFormik = withFormik<EditIngridientsOwnProps, UpdateAdditionIngredientValues>({
  enableReinitialize: true,
  mapPropsToValues: ({ ingridient }) => ({
    url: ingridient.url,
    name: ingridient.name,
    price: ingridient.price,
    type: 'ADDITIONAL'
  }),
  handleSubmit: (values, { props: { ingridient } }) => {
    ingridientStore.update(
      ingridient.id,
      { ...values, url: ingridient.url },
      Storage('sessionStorage').get('language') || 'UK'
    );
  },
  validationSchema: yup.object().shape<any>({
    name: yup
      .string()
      .min(2, 'Назва повинна мати не меньше 2 символів')
      .max(60, 'Назва повинна мати не більше 60 символів')
      .required('Це поле не може бути порожнім!')
  }),
  displayName: 'Add ingridients'
})(observer(EditAdditionIngridients));

export default EditAdditionIngridientsWithFormik;
